<template>
  <div class="div_page" :style="{ height: elementHeight }">
    <div class="div_bigplan">
      <div class="div_bigplan_head">
        <div class="div_plan">
          <span>
            <span class="plan_span_img">
              <img src="../../assets/water/plan.png" /> </span>&nbsp;规划列表
          </span>
        </div>
        <div class="div_plan_btn">
          <button class="div_btnUpt" @click="OpenUptBigPlan">
            <span class="btn_img">
              <img src="../../assets/water/update.png" />
            </span>
            <span class="btn_txt">修改规划</span>
          </button>
          <button class="div_btnDel" @click="DelBigplan">
            <span class="btn_img">
              <img src="../../assets/water/delete.png" />
            </span>
            <span class="btn_txt">删除规划</span>
          </button>
          <button class="div_btnAdd" @click="OpenAddBigPlan">
            <span>新增规划</span>
          </button>
        </div>
      </div>
      <div class="div_bigplan_hr">
        <hr />
      </div>
      <div>
        <el-table :data="BigPlanTitle" style="width: 98%" max-height="300" :show-header="false" border
          class="tab_BigPlan">
          <el-table-column prop="Text" width="120px;"></el-table-column>
          <template>
            <el-table-column v-for="items in BigPlan" :key="items.bigPlanNo" width="220px">
              <template slot-scope="scope">
                <template v-if="scope.row.Text == 'id'">
                  {{ items.id }}
                </template>
                <template v-else-if="scope.row.Text == '规划编号'">
                  <span @click="cellClick(items, $event)">
                    {{ items.bigPlanNo }}
                  </span>
                </template>
                <template v-else-if="scope.row.Text == '规划组长'">
                  <span @click="cellClick(items, $event)">
                    {{ items.groupLeaderName }}
                  </span>
                </template>
                <template v-else-if="scope.row.Text == '开始时间'">
                  <span @click="cellClick(items, $event)">
                    {{ items.beginTime | moment("YYYY-MM-DD") }}
                  </span>
                </template>
                <template v-else-if="scope.row.Text == '完成时间'">
                  <span @click="cellClick(items, $event)">
                    {{ items.finishTime | moment("YYYY-MM-DD") }}
                  </span>
                </template>
                <template v-else-if="scope.row.Text == '实际完成时间'">
                  <span @click="cellClick(items, $event)">
                    {{ items.saveTime | moment("YYYY-MM-DD HH:mm") }}
                  </span>
                </template>
                <template v-else>
                  <span @click="cellClick(items, $event)">
                    <button class="sel_report" id="selbigreport">
                      查看报表
                    </button>
                  </span>
                </template>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
    </div>
    <div class="div_smallplan" :style="{ height: smallHeight }">
      <div class="smallplan_head">
        <div class="div_bigplan_head">
          <div class="div_plan">
            <span>
              <span class="plan_span_img">
                <img src="../../assets/water/plan.png" /> </span>&nbsp;计划列表
              <span v-show="this.BigPlanId != ''">
                (
                <span v-text="this.BigPlanNo"></span>)
              </span>
            </span>
          </div>
          <div class="div_plan_btn">
            <button class="div_btnUpt" @click="OpenUptSmallPlan">
              <span class="btn_img">
                <img src="../../assets/water/update.png" />
              </span>
              <span class="btn_txt">修改计划</span>
            </button>
            <button class="div_btnDel" @click="DelSmallplan">
              <span class="btn_img">
                <img src="../../assets/water/delete.png" />
              </span>
              <span class="btn_txt">删除计划</span>
            </button>
            <button class="div_btnAdd" @click="OpenAddSmallPlan">
              <span>新增计划</span>
            </button>
          </div>
        </div>
        <div class="div_smallplan_hr">
          <hr />
        </div>
      </div>
      <div style="
          height: 5px;
          background-color: #ffffff;
          border: 1px solid #cfcfcf;
          border-bottom: 0px;
          border-top: 0px;
        "></div>
      <div class="smallPlanlist">
        <el-table :data="SmallPlan.slice(
          (SmallPlancurrentPage - 1) * SmallPlanpageSize,
          SmallPlancurrentPage * SmallPlanpageSize
        )
          " class="smallPlan_tab" style="width: 98%" stripe border current-row-key highlight-current-row
          max-height="290" @row-dblclick="smalldbclick" @row-click="smallclick">
          <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
          <el-table-column prop="compName" label="公司名称" width="180"></el-table-column>
          <el-table-column prop="planNo" label="检查编号" width="180"></el-table-column>
          <el-table-column prop="chargeUno" label="检查组组长编号" width="180"></el-table-column>
          <el-table-column prop="chargeName" label="检查组组长名称" width="180"></el-table-column>
          <el-table-column prop="auditUno" min-width="180" label="检查组成员编号"></el-table-column>
          <el-table-column prop="auditName" min-width="180" label="检查组成员名称"></el-table-column>
          <el-table-column fixed="right" label="操作" width="240">
            <template slot-scope="scope">
              <button @click="Sel_smallplan(scope.row)" class="sel_report" id="selsmallreport">
                查看报表</button>&nbsp;&nbsp;
              <button @click="smallUnqualified(scope.row)" class="sel_report1">
                不合格跟踪
              </button>
            </template>
          </el-table-column>
        </el-table>
        <div class="SmallPlanPage">
          <div style="float: right">
            <el-pagination @size-change="SmallPlanhandleSizeChange" @current-change="SmallPlanhandleCurrentChange"
              :current-page="SmallPlancurrentPage" :page-sizes="[5, 10, 20, 50]" :page-size="SmallPlanpageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="SmallPlan.length"></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="isNewBigPlan ? '新增规划' : '修改规划'" :visible.sync="dialogVisible" width="22%"
      :close-on-click-modal="false">
      <el-form ref="BigPlanForm" :model="{ BigPlanForm }">
        <el-form-item label="规划编号">
          <el-input style="width: 220px" v-model="BigPlanForm.bigPlanNo" placeholder="请输入规划编号"></el-input>
        </el-form-item>
        <el-form-item label="负责组长">
          <el-select v-model="BigPlanForm.groupLeader" @change="getgroupLeadercharge" placeholder="请选择负责组长">
            <el-option label="---请选择---" value></el-option>
            <el-option v-for="item in UserComp" :label="item.userName" :key="item.id" :value="item.userNo"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker v-model="BigPlanForm.beginTime" align="right" type="date" placeholder="选择开始日期"
            :picker-options="pickerOptions"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker v-model="BigPlanForm.finishTime" align="right" type="date" placeholder="选择结束日期"
            :picker-options="pickerOptions"></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveaddBigPlan">保存并新增</el-button>
        <el-button type="primary" @click="saveBigPlan">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="isNewSmallPlan ? '新增计划' : '修改计划'" :visible.sync="dialogSmall" width="22%"
      :close-on-click-modal="false">
      <el-form ref="SmallPlanForm" :model="{ SmallPlanForm }">
        <el-form-item label="检查对象">
          <el-select v-model="SmallPlanForm.compId" placeholder="请选择检查对象" @change="getCheckComp">
            <el-option v-for="item in checkComp" :label="item.compName" :key="item.id" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="检查编号">
          <el-input style="width: 220px" v-model="SmallPlanForm.planNo" placeholder="请输入检查编号"></el-input>
        </el-form-item>
        <el-form-item label="检查组长">
          <el-select v-model="SmallPlanForm.chargeUno" placeholder="请选择检查组长" @change="getcharge">
            <el-option v-for="item in UserComp" :label="item.userName" :key="item.id" :value="item.userNo"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="el-for-item" label="检查成员">
          <el-select @change="getaudit" class="el-input__diatext" multiple :collapse-tags="true" placeholder="请选择检查成员"
            v-model="SmallPlanForm.auditUno">
            <el-option v-for="item in UserComp" :key="item.id" :label="item.userName" :value="item.userNo"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考核政府单位" prop="type">
          <el-checkbox-group style="float: left; margin-left: 8px" v-model="SmallPlanForm.isAuditGovernment">
            <el-checkbox label name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveaddSmallPlan">保存并新增</el-button>
        <el-button type="primary" @click="saveSmallPlan">确 定</el-button>
      </span>
    </el-dialog>

    <div class="div_smallReport" ref="SmallReportBox" v-show="dialogSmallreport">
      <div class="div_smallReport_lf"></div>
      <div class="div_smallReport_ri">
        <p class="p_title">
          <span>
            <img src="../../assets/water/plan.png" />
          </span>
          &nbsp;计划报表({{ SmallPlanNo }})
        </p>
        <div class="smallmessage">
          <p class="p_comp">
            <img src="../../assets/water/bigplan.png" />
            <span class="smallComp">{{ smallCompName }}</span>
          </p>
          <p class="p_score">
            <span>考核得分：{{ smalltotalSoce }}</span>
            <span class="span_kSoce">扣分：{{ smalldutSoce }}</span>
          </p>
        </div>
        <div class="smallReportechart">
          <p class="p_smallScore">得分情况</p>
          <div class="smallleiDaTu" ref="smallleiDaTu"></div>
        </div>
        <div class="p_smallScore1">
          <p style="float: left; margin-top: 0px">扣分清单</p>
          <p class="p_ljkf">
            <span>累计扣分：&nbsp;</span>
            <span class="span_ljcount">{{ kfCount }}条</span>
            <span>累计扣分：&nbsp;</span>
            <span class="span_ljcount">{{ Deduction }}</span>
          </p>
        </div>
        <div class="div_kfList">
          <el-table :data="kfList" max-height="250" class="tabkfList" stripe :row-class-name="tableRowClassName" border>
            <el-table-column label="序号" type="index" width="90" align="center"></el-table-column>
            <el-table-column prop="evNo" label="检查编号" align="center"></el-table-column>
            <el-table-column prop="evAuditSoce" label="已扣分数" align="center"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <div class="div_smallReport" ref="BigReportBox" v-show="dialogBigreport">
      <div class="div_smallReport_lf"></div>
      <div class="div_smallReport_ri">
        <p class="p_title">
          <span>
            <img src="../../assets/water/plan.png" />
          </span>
          &nbsp;规划报表({{ BigPlanNo }})
        </p>
        <div class="bigmessage">
          <p class="p_timer">
            <img src="../../assets/water/timer.png" />
            <span class="bigtimer">{{ dbegintime }}&nbsp;-&nbsp;{{ dendtime }}</span>
          </p>
          <p class="p_score">
            <span>考核得分：{{ bigtotalSoce }}</span>
          </p>
          <p class="p_lycomp">
            <span>分数来源：{{ mixCompName }}</span>
          </p>
        </div>
        <div class="smallReportechart">
          <p class="p_smallScore">标准分</p>
          <div class="bigTu" ref="bigtongJiTu"></div>
        </div>
        <div class="p_smallScore1">
          <p style="float: left; margin-top: 0px">扣分清单</p>
        </div>
        <div class="div_kfList">
          <el-table :data="bigkfList" max-height="216" class="tabkfList" stripe :row-class-name="tableRowClassName"
            border>
            <el-table-column label="序号" type="index" width="90" align="center"></el-table-column>
            <el-table-column prop="companyName" label="公司名称" align="center"></el-table-column>
            <el-table-column prop="deductionSoce" label="已扣分数" align="center"></el-table-column>
            <el-table-column prop="totalSoce" label="总分数" align="center"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getHeader,
  get,
  DelHeader,
  postHeader,
  putHeader,
} from "../../api/http";
import $ from "jquery";
export default {
  //inject: ["reload"],
  data() {
    return {
      elementHeight:
        document.documentElement.clientHeight - 81.5 - 46 - 12 + "px",
      smallHeight: "",
      BigClassName: "",
      dialogSmallreport: false,
      UserNo: "",
      PassWord: "",
      compId: "",
      compName: "",
      BigPlan: [],
      mainReports: [],
      arrTotalSoce: [],
      arrevSoce: [],
      smallCompName: "",
      smalldutSoce: 0,
      smalltotalSoce: 0,
      SmallPlan: [],
      UserComp: [],
      checkComp: [],
      kfList: [],
      bigkfList: [],
      BigPlanId: "",
      SmallPlanId: "",
      SmallPlanNo: "",
      BigPlanNo: "",
      isNewBigPlan: true,
      dialogVisible: false,
      isNewSmallPlan: true,
      dialogSmall: false,
      dialogBigreport: false,
      SmallPlancurrentPage: 1,
      SmallPlanpageSize: 5,
      BigPlanForm: {
        id: 0,
        compangId: "",
        companyName: "",
        groupLeader: "",
        groupLeaderName: "",
        bigPlanNo: "",
        beginTime: "",
        finishTime: "",
        saveUserNo: "",
      },
      GroupLeader: "",
      GroupLeaderName: "",
      BeginTime: "",
      FinishTime: "",
      SmallPlanForm: {
        id: 0,
        bigPlanId: "",
        planNo: "",
        auditUno: [],
        auditName: "",
        chargeUno: "",
        chargeName: "",
        isAuditGovernment: true,
        compId: "",
        compName: "",
      },
      AuditUno: [],
      AuditName: "",
      ChargeUno: "",
      ChargeName: "",
      IsAuditGovernment: true,
      IsAdd: true,
      BigPlanTitle: [
        {
          Text: "id",
        },
        {
          Text: "规划编号",
        },
        {
          Text: "规划组长",
        },
        {
          Text: "开始时间",
        },
        {
          Text: "完成时间",
        },
        {
          Text: "实际完成时间",
        },
        {
          Text: "操作",
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      kfCount: 0,
      Deduction: 0,
      dbegintime: "",
      dendtime: "",
      bigtotalSoce: 0,
      mixCompName: "",
      bigreportsource: [],
    };
  },
  created() {
    document.addEventListener("click", (e) => {
      var Issmallreport = e.target.id == "selsmallreport" ? true : false;
      var Isbigreport = e.target.id == "selbigreport" ? true : false;
      if (this.$refs.SmallReportBox == undefined) {
        this.dialogBigreport = false;
        this.dialogSmallreport = false;
      } else {
        if (!this.$refs.SmallReportBox.contains(e.target)) {
          if (Issmallreport) {
            this.dialogSmallreport = true;
            this.dialogBigreport = false;
          } else {
            this.dialogSmallreport = false;
          }
        } else {
          this.dialogSmallreport = true;
          this.dialogBigreport = false;
        }
        if (!this.$refs.BigReportBox.contains(e.target)) {
          if (Isbigreport) {
            var bigid = $("." + this.BigClassName)[0].innerText;
            getHeader(
              "/BigPlanServiceTotal/BigPlanReport",
              { bigpianid: bigid },
              this.compId
            ).then((res) => {
              if (res.result == true) {
                this.dialogBigreport = true;
                this.dialogSmallreport = false;
              } else {
                this.dialogBigreport = false;
              }
            });
          } else {
            this.dialogBigreport = false;
          }
        } else {
          this.dialogBigreport = true;
          this.dialogSmallreport = false;
        }
      }
    });
  },
  mounted() {
    this.UserNo = JSON.parse(localStorage.getItem("User")).userNo;
    this.UserName = JSON.parse(localStorage.getItem("User")).userName;
    this.compId = JSON.parse(localStorage.getItem("User")).compId;
    this.smallHeight =
      document.querySelector(".div_page").height -
      document.querySelector(".div_head").height -
      document.querySelector(".div_bigplan").height +
      "px";
    this.GetBigPlan();
    this.bindComp();
    this.bindUserComp();
    this.getCompany();
  },
  activated() {
    /*this.$nextTick(function() {
          this.drawPie('leiDaTu')
        });*/
  },
  methods: {
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return "oddRow";
      }
      return "evenRow";
    },
    SmallPlanhandleSizeChange(val) {
      this.SmallPlancurrentPage = 1;
      this.SmallPlanpageSize = val;
    },
    SmallPlanhandleCurrentChange(val) {
      this.SmallPlancurrentPage = val;
    },
    drawPie() {
      if (this.$refs.smallleiDaTu == null) {
        return;
      }
      this.$echarts.dispose(this.$refs.smallleiDaTu);
      let charts = this.$echarts.init(this.$refs.smallleiDaTu);
      var option = {
        tooltip: {}, //提示层
        color: ["hsla(163, 70%, 59%, 1)", "hsla(39, 100%, 65%, 1)"],
        legend: {
          orient: "vertical",
          align: "left",
          x: "right",
          y: "top",
          icon: "circle",
          itemWidth: 14,
          itemHeight: 14,
          padding: [20, 50, 0, 0],
          textStyle: {
            color: "#575757",
          },
          data: ["本次扣分", "总扣分"],
        },
        radar: {
          axisName: {
            textStyle: {
              color: "#9C9C9C", //字体颜色
              //borderRadius: 3, //圆角
              padding: [3, 5], //padding
            },
            formatter: function (value) {
              let list = value.split("");
              let result = "";
              for (let i = 1; i <= list.length; i++) {
                if (!(i % 6) && list[i] != undefined) {
                  result += list[i - 1] + "\n\n";
                } else {
                  result += list[i - 1];
                }
              }
              return result;
            },
          },
          center: ["50%", "50%"],
          radius: "60%",
          startAngle: 270,
          axisLine: {
            // (圆内的几条直线)坐标轴轴线相关设置
            lineStyle: {
              color: "#CDCDCD",
              // 坐标轴线线的颜色。
              width: 1,
              // 坐标轴线线宽。
              type: "solid",
              // 坐标轴线线的类型。
            },
          },
          indicator: this.mainReports,
        },
        series: [
          {
            type: "radar",
            //symbol: "none",
            name: "测试标题名字",
            data: [
              {
                value: this.arrevSoce,
                name: "本次扣分",
                label: {
                  show: true,
                },
                lineStyle: {
                  color: "hsla(204, 32%, 51%, 1)",
                },
                areaStyle: {
                  color: "hsla(163, 99%, 41%, 1)", // 选择区域颜色
                },
              },
              {
                value: this.arrTotalSoce,
                name: "总扣分",
                label: {
                  show: true,
                },
                lineStyle: {
                  color: "hsla(204, 32%, 51%, 1)",
                },
                areaStyle: {
                  color: "hsla(39, 100%, 65%, 1)", // 选择区域颜色
                },
              },
            ],
          },
        ],
      };
      charts.setOption(option);
    },
    drawTjitu() {
      if (this.$refs.bigtongJiTu == null) {
        return;
      }
      this.$echarts.dispose(this.$refs.bigtongJiTu);
      let charts = this.$echarts.init(this.$refs.bigtongJiTu);
      var option = {
        legend: {
          show: true,
          right: "30px",
        },
        grid: {
          height: "370px",
          left: "58px",
          top: "30px",
        },
        tooltip: {},
        dataset: {
          dimensions: ["companyName", "currentSoce"],
          source: this.bigreportsource,
        },
        formatter: function () {
          return "本次标准分";
        },
        xAxis: {
          type: "value",
          splitLine: {
            //网格线
            show: false,
          },
          axisLabel: {
            show: false,
            color: "#4E5354",
          },
          axisTick: {
            //轴刻度线
            show: false,
            color: "#C0C0C0",
          },
          axisLine: {
            //轴线
            show: false,
            color: "#C0C0C0",
          },
        },
        yAxis: {
          type: "category",
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: "#4E5354",
            fontWeight: "1000",
            formatter: function (value) {
              let list = value.split("");
              let result = "";
              for (let i = 1; i <= list.length; i++) {
                if (!(i % 4) && list[i] != undefined) {
                  result += list[i - 1] + "\n";
                } else {
                  result += list[i - 1];
                }
              }
              return result;
            },
          },
          axisTick: {
            //轴刻度线
            show: true,
            color: "#C0C0C0",
          },
          axisLine: {
            //轴线
            show: true,
            color: "#C0C0C0",
          },
        },
        series: [
          {
            type: "bar",
            color: "hsla(39, 100%, 50%, 1)",
            stack: "25",
            //设置柱状图大小
            barWidth: 16,
            label: {
              show: true, // 显示数值
              position: "right", // 数值在柱体上方
            },
          },
        ],
      };
      charts.setOption(option);
    },
    getCompany() {
      get("/Company/GetCompanyList", { compId: this.compId })
        .then((resp) => {
          if (resp.result == true) {
            this.checkComp = [];
            if (resp.value != null) {
              this.checkComp = resp.value;
            }
          }
        })
        .catch((error) => {
          console.log("error=" + JSON.stringify(error));
        });
    },
    GetBigPlan() {
      getHeader("/BigPlan/" + this.compId, null, this.compId)
        .then((resp) => {
          if (resp.result == true) {
            this.BigPlan = [];
            if (resp.value != null) {
              this.BigPlan = resp.value;
            }
          }
        })
        .catch((error) => {
          console.log("error=" + JSON.stringify(error));
        });
    },
    GetSmallPlan() {
      getHeader("/WsmPlanMain/" + this.BigPlanId, null, this.compId)
        .then((resp) => {
          if (resp.result == true) {
            if (resp.value != null) {
              this.SmallPlan = resp.value;
            }
          }
        })
        .catch((error) => {
          console.log("error=" + JSON.stringify(error));
        });
    },
    cellClick(e, event) {
      var Isbutton = event.path[0].innerText == "查看报表" ? true : false;
      if (Isbutton) {
        this.BigClassName =
          event.path[3].attributes[2] == undefined
            ? event.path[2].attributes[2].nodeValue
            : event.path[3].attributes[2].nodeValue;
        $(".tab_BigPlan tr td").css("background-color", "#FFFFFF");
        $(".tab_BigPlan tr td:nth-of-type(odd)").css(
          "background-color",
          "#f9f9f9"
        );
        $(".tab_BigPlan tr ." + this.BigClassName).css(
          "background-color",
          "#E9F7FF"
        );
        getHeader(
          "/BigPlanServiceTotal/BigPlanReport",
          { bigpianid: e.id },
          this.compId
        ).then((res) => {
          console.log(res);
          this.bigkfList = [];
          this.dbegintime = "";
          this.dendtime = "";
          this.bigtotalSoce = 0;
          this.mixCompName = "";
          this.bigreportsource = [];
          if (res.result == true) {
            this.bigkfList = res.otherValue;
            this.dbegintime = new Date(res.value.beginTime).Tostring(
              "yyyy.MM.dd"
            );
            this.dendtime = new Date(res.value.endTime).Tostring("yyyy.MM.dd");
            this.bigtotalSoce = res.value.minSoce;
            this.mixCompName = res.value.mixCompName;
            this.bigreportsource = res.value.compDtos;
            this.drawTjitu();
          } else {
            this.$message({
              message: "规划编号[" + e.bigPlanNo + "]未产生报表！",
              type: "warning",
            });
          }
        });
      } else {
        this.BigClassName = event.path[2].attributes[2].nodeValue;
        $(".tab_BigPlan tr td").css("background-color", "#FFFFFF");
        $(".tab_BigPlan tr td:nth-of-type(odd)").css(
          "background-color",
          "#f9f9f9"
        );
        $(".tab_BigPlan tr ." + this.BigClassName).css(
          "background-color",
          "#E9F7FF"
        );
      }
      this.BigPlanId = e.id;
      this.BigPlanNo = e.bigPlanNo;
      this.GroupLeader = e.groupLeader;
      this.GroupLeaderName = e.groupLeaderName;
      this.BeginTime = e.beginTime;
      this.FinishTime = e.finishTime;
      this.GetSmallPlan();
    },
    OpenAddBigPlan() {
      this.isNewBigPlan = true;
      this.dialogVisible = true;
      this.BigPlanForm = {
        id: 0,
        compangId: "" + this.compId,
        companyName: this.compName,
        groupLeader: "",
        groupLeaderName: "",
        bigPlanNo: "",
        beginTime: "",
        finishTime: "",
        saveUserNo: this.UserNo,
      };
    },
    OpenUptBigPlan() {
      if (this.BigPlanId == "") {
        this.$message.error("请选择要修改的规划");
        return;
      }
      this.BigPlanForm = {
        id: this.BigPlanId,
        compangId: "" + this.compId,
        companyName: this.compName,
        groupLeader: this.GroupLeader,
        groupLeaderName: this.GroupLeaderName,
        bigPlanNo: this.BigPlanNo,
        beginTime: this.BeginTime,
        finishTime: this.FinishTime,
        saveUserNo: this.UserNo,
      };
      this.isNewBigPlan = false;
      this.dialogVisible = true;
    },
    OpenAddSmallPlan() {
      if (this.BigPlanId == "") {
        this.$message.error("请先选择规划");
        return;
      }
      this.isNewSmallPlan = true;
      this.dialogSmall = true;
      this.SmallPlanForm = {
        id: 0,
        bigPlanId: "" + this.BigPlanId,
        planNo: "",
        auditUno: [],
        auditName: "",
        chargeUno: "",
        chargeName: "",
        isAuditGovernment: true,
        compId: this.compId,
        compName: this.compName,
      };
    },
    OpenUptSmallPlan() {
      if (this.SmallPlanId == "") {
        this.$message.error("请选择要修改的计划");
        return;
      }
      this.SmallPlanForm = {
        id: this.SmallPlanId,
        bigPlanId: "" + this.BigPlanId,
        planNo: this.SmallPlanNo,
        auditUno: this.AuditUno,
        auditName: this.AuditName,
        chargeUno: this.ChargeUno,
        chargeName: this.ChargeName,
        isAuditGovernment: this.IsAuditGovernment,
        compId: this.compId,
        compName: this.compName,
      };
      this.isNewSmallPlan = false;
      this.dialogSmall = true;
    },
    DelBigplan() {
      if (this.BigPlanId == "") {
        this.$message.error("请选择要删除的规划");
        return;
      }
      this.$confirm(
        "此操作将永久删除编号为[" + this.BigPlanNo + "]的规划, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          DelHeader("/BigPlan/", this.BigPlanId, this.compId)
            .then((resp) => {
              console.log(resp);
              if (resp.result == true) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.BigPlanId = "";
                this.BigPlanNo = "";
                $(".tab_BigPlan tr td").css("background-color", "#FFFFFF");
                $(".tab_BigPlan tr td:nth-of-type(odd)").css(
                  "background-color",
                  "#f9f9f9"
                );
                this.GetBigPlan();
                this.SmallPlan = [];
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => { });
    },
    DelSmallplan() {
      if (this.SmallPlanId == "") {
        this.$message.error("请选择要删除的计划");
        return;
      }
      this.$confirm(
        "此操作将永久删除编号为[" + this.SmallPlanNo + "]的计划, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          DelHeader("/WsmPlanMain/", this.SmallPlanId, this.compId)
            .then((resp) => {
              console.log(resp);
              if (resp.result == true) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.SmallPlanId = "";
                this.SmallPlanNo = "";
                this.GetSmallPlan();
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => { });
    },
    smallclick(e) {
      this.SmallPlanId = e.id;
      this.SmallPlanNo = e.planNo;
      var auditUno = [];
      if (e.auditUno != "") {
        auditUno = e.auditUno.split(",");
      }
      this.AuditUno = auditUno;
      this.AuditName = e.auditName;
      this.ChargeUno = e.chargeUno;
      this.ChargeName = e.chargeName;
      this.IsAuditGovernment = e.isAuditGovernment;
    },
    smalldbclick(e) {
      var sSmallPlanId = e.id;
      var bisGvo = e.isAuditGovernment;
      this.$router.push({
        path: "/home/Checktab",
        name: "检查列表",
        params: { index: 1, SmallPlanId: sSmallPlanId, isGvo: bisGvo },
      });
    },
    smallUnqualified(e) {
      var sSmallPlanId = e.id;
      this.$router.push({
        path: "/home/Unqualified",
        name: "不合格跟踪",
        params: { index: 2, SmallPlanId: sSmallPlanId },
      });
    },
    bindComp() {
      get("/Company/" + this.compId)
        .then((res) => {
          if (res.result == true) {
            this.compName = res.value.compName;
          }
        })
        .catch((error) => {
          console.log("error=" + JSON.stringify(error));
        });
    },
    saveaddBigPlan() {
      var bigPlan = this.BigPlanForm;
      if (bigPlan.bigPlanNo == "") {
        this.$message.error("请输入规划编号");
        return;
      }
      if (bigPlan.groupLeader == "") {
        this.$message.error("请选择负责组长");
        return;
      }
      if (bigPlan.beginTime == "") {
        this.$message.error("请选择开始时间");
        return;
      }
      if (bigPlan.finishTime == "") {
        this.$message.error("请选择结束时间");
        return;
      }
      if (Date.parse(bigPlan.finishTime) < Date.parse(bigPlan.beginTime)) {
        this.$message.error("结束时间不能小于开始时间");
        return;
      }
      if (bigPlan.id == 0) {
        postHeader("/BigPlan", JSON.stringify(bigPlan), this.compId)
          .then((res) => {
            if (res.result == true) {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.OpenAddBigPlan();
              this.BigPlanId = "";
              this.BigPlanNo = "";
              $(".tab_BigPlan tr td").css("background-color", "#FFFFFF");
              $(".tab_BigPlan tr td:nth-of-type(odd)").css(
                "background-color",
                "#f9f9f9"
              );
              this.GetBigPlan();
              this.SmallPlan = [];
              this.IsAdd = true;
            } else {
              this.$message.error(res.message);
            }
          })
          .catch(() => {
            this.$message.error("新增失败");
          });
      } else {
        putHeader(
          "/BigPlan/" + bigPlan.id,
          JSON.stringify(bigPlan),
          this.compId
        )
          .then((res) => {
            if (res.result == true) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              //this.BigPlanNo = bigPlan.bigPlanNo;
              this.BigPlanId = "";
              this.BigPlanNo = "";
              $(".tab_BigPlan tr td").css("background-color", "#FFFFFF");
              $(".tab_BigPlan tr td:nth-of-type(odd)").css(
                "background-color",
                "#f9f9f9"
              );
              this.OpenAddBigPlan();
              this.GetBigPlan();
              this.SmallPlan = [];
              this.IsAdd = false;
            } else {
              this.$message.error(res.message);
            }
          })
          .catch(() => {
            this.$message.error("修改失败");
          });
      }
    },
    saveBigPlan() {
      var bigPlan = this.BigPlanForm;
      if (bigPlan.bigPlanNo == "") {
        this.$message.error("请输入规划编号");
        return;
      }
      if (bigPlan.groupLeader == "") {
        this.$message.error("请选择负责组长");
        return;
      }
      if (bigPlan.beginTime == "") {
        this.$message.error("请选择开始时间");
        return;
      }
      if (bigPlan.finishTime == "") {
        this.$message.error("请选择结束时间");
        return;
      }
      if (Date.parse(bigPlan.finishTime) < Date.parse(bigPlan.beginTime)) {
        this.$message.error("结束时间不能小于开始时间");
        return;
      }
      if (bigPlan.id == 0) {
        postHeader("/BigPlan", JSON.stringify(bigPlan), this.compId)
          .then((res) => {
            console.log(res);
            if (res.result == true) {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.dialogVisible = false;
              this.BigPlanId = "";
              this.BigPlanNo = "";
              $(".tab_BigPlan tr td").css("background-color", "#FFFFFF");
              $(".tab_BigPlan tr td:nth-of-type(odd)").css(
                "background-color",
                "#f9f9f9"
              );
              this.GetBigPlan();
              this.SmallPlan = [];
              this.IsAdd = true;
            } else {
              this.$message.error(res.message);
            }
          })
          .catch(() => {
            this.$message.error("新增失败");
          });
      } else {
        putHeader(
          "/BigPlan/" + bigPlan.id,
          JSON.stringify(bigPlan),
          this.compId
        )
          .then((res) => {
            if (res.result == true) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              console.log(this.BigPlanForm);

              this.dialogVisible = false;
              //this.BigPlanNo = bigPlan.bigPlanNo;
              this.BigPlanId = "";
              this.BigPlanNo = "";
              $(".tab_BigPlan tr td").css("background-color", "#FFFFFF");
              $(".tab_BigPlan tr td:nth-of-type(odd)").css(
                "background-color",
                "#f9f9f9"
              );
              this.GetBigPlan();
              this.SmallPlan = [];
              this.IsAdd = false;
            } else {
              this.$message.error(res.message);
            }
          })
          .catch(() => {
            this.$message.error("修改失败");
          });
      }
    },
    saveaddSmallPlan() {
      var smallPlan = this.SmallPlanForm;
      if (smallPlan.compId == "") {
        this.$message.error("请选择检查对象");
        return;
      }
      if (smallPlan.planNo == "") {
        this.$message.error("请输入检查编号");
        return;
      }
      if (smallPlan.chargeUno == "") {
        this.$message.error("请选择检查组长");
        return;
      }
      if (smallPlan.auditUno == "") {
        this.$message.error("请选择检查成员");
        return;
      }
      var auditUno = "";
      smallPlan.auditUno.forEach((e) => {
        auditUno = auditUno + e + ",";
      });
      auditUno = auditUno.substring(0, auditUno.length - 1);
      smallPlan.auditUno = auditUno;
      if (smallPlan.id == 0) {
        postHeader("/WsmPlanMain", JSON.stringify(smallPlan), this.compId)
          .then((res) => {
            if (res.result == true) {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.OpenAddSmallPlan();
              this.GetSmallPlan();
            } else {
              this.$message.error(res.message);
            }
          })
          .catch(() => {
            this.$message.error("新增失败");
          });
      } else {
        putHeader(
          "/WsmPlanMain/" + smallPlan.id,
          JSON.stringify(smallPlan),
          this.compId
        )
          .then((res) => {
            if (res.result == true) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.OpenAddSmallPlan();
              this.GetSmallPlan();
              this.SmallPlanId = "";
            } else {
              this.$message.error(res.message);
            }
          })
          .catch(() => {
            this.$message.error("修改失败");
          });
      }
    },
    saveSmallPlan() {
      var smallPlan = this.SmallPlanForm;
      if (smallPlan.compId == "") {
        this.$message.error("请选择检查对象");
        return;
      }
      if (smallPlan.planNo == "") {
        this.$message.error("请输入检查编号");
        return;
      }
      if (smallPlan.chargeUno == "") {
        this.$message.error("请选择检查组长");
        return;
      }
      if (smallPlan.auditUno == "") {
        this.$message.error("请选择检查成员");
        return;
      }
      var auditUno = "";
      smallPlan.auditUno.forEach((e) => {
        auditUno = auditUno + e + ",";
      });
      auditUno = auditUno.substring(0, auditUno.length - 1);
      smallPlan.auditUno = auditUno;
      if (smallPlan.id == 0) {
        postHeader("/WsmPlanMain", JSON.stringify(smallPlan), this.compId)
          .then((res) => {
            console.log(res);
            if (res.result == true) {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.dialogSmall = false;
              this.GetSmallPlan();
            } else {
              this.$message.error(res.message);
            }
          })
          .catch(() => {
            this.$message.error("新增失败");
          });
      } else {
        putHeader(
          "/WsmPlanMain/" + smallPlan.id,
          JSON.stringify(smallPlan),
          this.compId
        )
          .then((res) => {
            if (res.result == true) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.dialogSmall = false;
              this.GetSmallPlan();
              this.SmallPlanId = "";
            } else {
              this.$message.error(res.message);
            }
          })
          .catch(() => {
            this.$message.error("修改失败");
          });
      }
    },
    bindUserComp() {
      get("/User/GetUserListByCompangId", { compid: this.compId })
        .then((res) => {
          if (res.result == true) {
            this.UserComp = res.value;
          }
        })
        .catch((error) => {
          console.log("error=" + JSON.stringify(error));
        });
    },
    getCheckComp(val) {
      this.SmallPlanForm.compName = this.checkComp.filter(
        (e) => e.id == val
      )[0].compName;
    },
    getcharge(val) {
      this.SmallPlanForm.chargeName = this.UserComp.filter(
        (e) => e.userNo == val
      )[0].userName;
    },
    getgroupLeadercharge(val) {
      if (val == "") {
        this.BigPlanForm.groupLeaderName = "";
      } else {
        this.BigPlanForm.groupLeaderName = this.UserComp.filter(
          (e) => e.userNo == val
        )[0].userName;
      }
    },
    getaudit(val) {
      var auditName = "";
      val.forEach((e) => {
        var chargeName = this.UserComp.filter((o) => o.userNo == e)[0].userName;
        auditName = auditName + chargeName + ",";
      });
      if (auditName != "") {
        auditName = auditName.substring(0, auditName.length - 1);
      }
      this.SmallPlanForm.auditName = auditName;
    },
    Sel_smallplan(e) {
      getHeader(
        "/WsmPlanDetail/GetPlanMainReprot",
        { planId: e.id },
        this.compId
      )
        .then((res) => {
          //console.log(res);
          this.mainReports = [];
          this.arrevSoce = [];
          this.arrTotalSoce = [];
          this.kfList = [];
          this.smallCompName = "";
          this.smalldutSoce = 0;
          this.smalltotalSoce = 0;
          this.kfCount = 0;
          this.Deduction = 0;
          if (res.result == true) {
            this.kfCount = res.otherValue.length;
            res.otherValue.forEach((e) => {
              this.Deduction += e.evAuditSoce;
            });
            this.kfList = res.otherValue;
            /*var nmax = 0;
              res.value.mainReports.forEach((e) => {
                if(nmax<e.evTotal){
                  nmax=e.evTotal;
                }
              });
                res.value.mainReports.forEach((e) => {
                  var total = {
                    name: e.evName,
                    max: nmax,
                  };
                  this.arrTotalSoce.push(e.evTotal);
                  this.mainReports.push(total);
                  this.arrevSoce.push(e.evSoce);
                });*/
            res.value.mainReports.forEach((e) => {
              var total = {
                name: e.evName,
                max: e.evTotal,
              };
              this.arrTotalSoce.push(e.evTotal);
              this.mainReports.push(total);
              this.arrevSoce.push(e.evSoce);
            });
            this.smallCompName = res.value.companyName;
            this.smalldutSoce = res.value.dutSoce;
            this.smalltotalSoce = res.value.totalSoce;
            if (this.mainReports.length > 0) {
              this.drawPie("leiDaTu");
            }
          }
        })
        .catch((error) => {
          console.log("error=" + JSON.stringify(error));
        });
    },
  },
};
</script>

<style scoped>
.el-table thead th {
  background: #f0f0f0;
}

.div_page {
  width: 100%;
}

.div_bigplan {
  width: 95%;
  height: 383px;
  /*margin-top: 12px;*/
  margin-left: 43px;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 0px 1px 0px rgba(72, 72, 72, 0.17);
}

.div_smallplan {
  width: 95.1%;
  margin-top: 10px;
  margin-left: 43px;
  background: #f5f5f5;
  /*box-shadow: 0px 0px 1px 0px rgba(72, 72, 72, 0.17);*/
}

.div_bigplan_head {
  width: 98%;
  height: 57px;
  margin-left: 20px;
}

.div_bigplan_hr hr {
  width: 97.4%;
  margin-left: 23px;
  border: 2px solid #eff0f1;
}

.div_smallplan_hr {
  border: 1px solid #ffffff;
  border-left: 0px;
  border-right: 0px;
}

.div_smallplan_hr hr {
  width: 97.4%;
  margin-left: 23px;
  border: 2px solid #eff0f1;
}

.div_plan {
  float: left;
  height: 100%;
  line-height: 57px;
}

.div_plan span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.plan_span_img {
  display: block;
  float: left;
  margin-top: 5px;
}

.div_plan_btn {
  float: right;
  line-height: 57px;
}

.div_btnDel {
  width: 106px;
  height: 40px;
  background: #ffe9e9;
  border: 1px solid #ffaaaa;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fc7c7c;
}

.div_btnUpt {
  width: 106px;
  height: 40px;
  background: #e9f7ff;
  border: 1px solid #7caffc;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #7caffc;
}

.div_btnAdd {
  width: 106px;
  height: 40px;
  background: #ffa400;
  border-radius: 4px;
  border: 1px solid #ffa400;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}

.btn_img {
  display: block;
  float: left;
  margin-top: 6px;
  margin-left: 10px;
}

.btn_txt {
  display: block;
  margin-top: 4px;
  margin-right: 5px;
}

button {
  outline: none;
}

.smallplan_head {
  background-color: #ffffff;
  border: 1px solid #cfcfcf;
  border-bottom: 0px;
}

.sel_report {
  width: 75px;
  height: 23px;
  border: 1px solid #578cfd;
  border-radius: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #578cfd;
  background-color: #ffffff;
}

.sel_report1 {
  width: 94px;
  height: 23px;
  border: 1px solid #578cfd;
  border-radius: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #578cfd;
  background-color: #ffffff;
}

.tab_BigPlan {
  margin-top: 10px;
  margin-left: 20px;
  border: 1px solid #cfcfcf;
}

.tab_BigPlan tr td {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  height: 46px;
  padding: 0px;
  margin: 0px;
  text-align: center;
}

.tab_BigPlan tr td div {
  width: 220px;
  height: 100%;
  padding: 0px;
  margin: 0px;
  display: table-cell;
  vertical-align: middle;
}

.tab_BigPlan tr td span {
  padding: 0px;
  margin: 0px;
  display: block;
  width: 104%;
  height: 100%;
  line-height: 46px;
}

.tab_BigPlan tr:first-child {
  display: none;
}

.tab_BigPlan tr td:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.tab_BigPlan tr td:first-child {
  text-align: left;
  padding-left: 12px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.tab_BigPlan tbody tr:hover td {
  background-color: #fff !important;
}

.tab_BigPlan tbody tr:hover td:nth-of-type(odd) {
  background-color: #f9f9f9 !important;
}

.smallPlanlist {
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-top: 0px;
}

.smallPlan_tab {
  margin-left: 20px;
  border: 1px solid #cfcfcf;
}

.smallPlan_tab thead tr th {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.smallPlan_tab tr td {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}

.SmallPlanPage {
  float: right;
  position: relative;
  padding: 10px 0;
  width: 100%;
  left: 1px;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-top: 0px;
}

.smallPlan_tab thead {
  background-color: #f9f9f9 !important;
}

.el-dialog__body {
  padding: 0px;
  margin: 0px;
}

.div_smallReport {
  position: absolute;
  z-index: 4;
  width: 592px;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: darkgrey 10px 10px 30px 5px;
}

.div_smallReport_lf {
  float: left;
  width: 10px;
  height: 100%;
  background: #000000;
  opacity: 0.36;
}

.div_smallReport_ri {
  float: left;
  width: 582px;
  background: #ffffff;
  height: 100%;
}

.pevents {
  pointer-events: none;
}

.smallReportechart {
  margin-top: 10px;
  width: 582px;
  height: 440px;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 0px 1px 0px rgba(72, 72, 72, 0.17);
  float: left;
}

.p_smallScore {
  margin: 15px 15px;
  padding-left: 16px;
  width: 537px;
  height: 45px;
  line-height: 45px;
  background: #f7f4f5;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #575757;
  float: left;
  margin-bottom: 0px;
}

.p_smallScore1 {
  margin: 15px 12px;
  padding-left: 16px;
  width: 537px;
  height: 45px;
  line-height: 45px;
  background: #f7f4f5;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #575757;
  float: left;
  margin-bottom: 0px;
}

.smallleiDaTu {
  width: 582px;
  height: 380px;
  float: left;
}

.bigTu {
  margin-top: 5px;
  width: 582px;
  height: 370px;
  float: left;
  overflow: auto;
}

.p_title {
  float: left;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  margin-left: 29px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.p_title span {
  display: block;
  float: left;
  margin-top: 3px;
}

.smallmessage {
  float: left;
  width: 537px;
  height: 125px;
  background: linear-gradient(90deg, #2776eb 0%, #5ac0ff 100%);
  border-radius: 21px;
  margin-left: 23px;
  margin-top: 10px;
}

.p_comp {
  padding-left: 35px;
  margin-top: 28px;
  height: 21px;
  line-height: 21px;
  margin-bottom: 0px;
}

.p_comp img {
  float: left;
}

.smallComp {
  display: block;
  float: left;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-left: 1px;
}

.p_score {
  float: left;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #d7eeff;
  padding-left: 37px;
  margin-top: 24px;
  margin-bottom: 0px;
}

.p_score span {
  display: block;
  float: left;
}

.p_lycomp {
  float: left;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #d7eeff;
  padding-left: 37px;
  width: 500px;
  margin-top: 12px;
}

.p_lycomp span {
  display: block;
  float: left;
}

.span_kSoce {
  margin-left: 20px;
}

.p_ljkf {
  float: right;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #232323;
  margin-top: 0px;
}

.p_ljkf span {
  display: block;
  float: left;
}

.span_ljcount {
  color: hsla(0, 100%, 50%, 1);
  margin-right: 18px;
}

.tabkfList thead th {
  background: #56aafd;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
}

.oddRow {
  background-color: #ffffff;
}

.evenRow {
  background-color: #f9f9f9;
}

.div_kfList {
  width: 523px;
  float: left;
  margin: 13px 30px;
  margin-bottom: 0px;
}

.bigtimer {
  display: block;
  float: left;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-left: 1px;
}

.p_timer {
  padding-left: 35px;
  margin-top: 28px;
  height: 21px;
  line-height: 21px;
  margin-bottom: 0px;
}

.p_timer img {
  float: left;
  margin-top: 2px;
}

.bigmessage {
  float: left;
  width: 537px;
  height: 159px;
  background: linear-gradient(90deg, #2776eb 0%, #5ac0ff 100%);
  border-radius: 21px;
  margin-left: 23px;
  margin-top: 10px;
}
</style>
